import React, { useState, useEffect, useContext } from "react";
import { login, getOfflineList } from "../services/consts";
import OurHeader from "../components/OurHeader";

function Login(props) {
  const defaultSettings = {
    percup: 10,
    markup: 1,
  };

  const [settings, setSettings] = useState(defaultSettings);
  const [error, setError] = useState(null);

  const bootup = async () => {
    const userinfo = await login(
      settings.username,
      settings.password,
      async (user) => {
        if (user !== null && user !== "not_found") {
          console.log(user);
          localStorage["userinfo"] = JSON.stringify(user);
          const offlinelist = localStorage["offlinelist"];
          if (
            offlinelist === undefined ||
            offlinelist.id !== user.pricelist_id
          ) {
            await getOfflineList((data) => {
              console.log("got list", data);
              localStorage["offlinelist"] = JSON.stringify({
                id: user.pricelist_id,
                items: data,
                date: new Date().toLocaleDateString(),
              });
            });
          }
          window.location.reload();
        } else {
          console.log("User Not Found");
          localStorage.removeItem("userinfo");
          localStorage.removeItem("offlinelist");
          return false;
        }
      }
    );
  };

  const setUsername = (evt) => {
    settings.username = evt.target.value;

    setSettings(settings);
  };

  const setPassword = (evt) => {
    console.log("SETTING PASSWORD");
    settings.password = evt.target.value;
    setSettings(settings);
  };

  const saveSettings = () => {
    localStorage["userinfo"] = JSON.stringify(settings);
    bootup();
  };

  const loadSettings = () => {
    if (localStorage["userinfo"]) {
      setSettings(JSON.parse(localStorage["userinfo"]));
    }
  };

  useEffect(() => {
    loadSettings();
  }, []);

  return (
    <div>
      <OurHeader history={props.history}></OurHeader>
      <div class="container">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <img
            style={{ maxWidth: "300px", marginTop: 100 }}
            src="/images/apex-logo-hi-res.png"
          />
        </div>
        <form style={{ marginTop: "40px" }}>
          {error && <h6 style={{ color: "red" }}>{error}</h6>}
          <div class="form-group">
            <label for="exampleInputEmail1">Username</label>
            <input
              type="text"
              onInput={setUsername}
              value={settings.username}
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">Password</label>
            <input
              type="password"
              value={settings.password}
              onInput={setPassword}
              class="form-control"
            />
          </div>
          <button
            type="button"
            onClick={() => saveSettings()}
            class="btn btn-primary pull-right"
          >
            Sign In
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
