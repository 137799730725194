import React, { useState, useEffect, useRef } from "react";
import { api_base, loadUsers } from "../services/consts";
import OurHeader from "./OurHeader";
import GoogleMapReact from "google-map-react";

function Stats(props) {
  const [offsetType, setOffsetType] = useState("perc");
  const [offset, setOffset] = useState(0);
  const [offlineListDate, setOfflineListDate] = useState("");

  const handleOffsetChange = (e) => {
    const offset = e.target.value;
    setOffset(offset);
  };

  const saveSettings = () => {
    console.log("saving settings");
    localStorage["usersettings"] = JSON.stringify({
      offsetType: offsetType,
      offset: offset,
    });
    alert("Settings Saved");
  };

  const loadUserSettings = () => {
    console.log("loading settings");
    let settings;
    localStorage["usersettings"]
      ? (settings = JSON.parse(localStorage["usersettings"]))
      : (settings = { offset: 0 });

    console.log("GOT SETTINGS", settings);

    setOffset(settings.offset);
    setOffsetType(settings.offsetType);

    const offlinelist = JSON.parse(localStorage["offlinelist"]);
    console.log("GOT OFFLINE LIST", offlinelist);
    if (offlinelist === undefined || offlinelist.date !== undefined) {
      setOfflineListDate(offlinelist.date);
    }
  };

  useEffect(() => {
    loadUserSettings();
  }, []);

  return (
    <div>
      <OurHeader history={props.history}></OurHeader>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <form style={{ width: 400, marginTop: 150 }}>
          <h6>Last Pricelist Fetched: {offlineListDate}</h6>
          <div class="form-group">
            <div class="btn-group" role="group">
              {offsetType === "perc" ? (
                <button type="button" class="btn btn-primary">
                  Percentage
                </button>
              ) : (
                <button
                  //onClick={}
                  type="button"
                  onClick={() => setOffsetType("perc")}
                  class="btn btn-secondary"
                >
                  Percentage
                </button>
              )}

              {offsetType === "fixed" ? (
                <button type="button" class="btn btn-primary">
                  Fixed
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => setOffsetType("fixed")}
                  class="btn btn-secondary"
                >
                  Fixed
                </button>
              )}
            </div>
          </div>

          <div class="form-group">
            <label for="exampleInputEmail1">Offset</label>
            <input
              value={offset}
              onChange={handleOffsetChange}
              type="text"
              class="form-control"
            />
          </div>

          <button
            type="button"
            onClick={() => saveSettings()}
            class="btn btn-primary"
          >
            Save changes
          </button>
        </form>
      </div>
    </div>
  );
}
export default Stats;
