import React, { useState, useEffect, useDebugValue } from "react";
import { api_base, loadUsers, updateUser } from "../services/consts";
import OurHeader from "./OurHeader";

function AssignPricelists(props) {
  const [pricelists, setPricelists] = useState([]);
  const [users, setUsers] = useState([]);

  const loadPricelists = () => {
    console.log("loading pricelists");

    fetch(api_base + "/pricelists/getAll", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res) {
          return res.json();
        }
        return null;
      })
      .then((data) => {
        console.log(data);
        setPricelists(data);
      });
  };

  const removePricelist = (pricelist_id) => {
    fetch(api_base + "/pricelists/remove", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ pricelist_id: pricelist_id }),
    })
      .then((res) => {
        if (res) {
          return res;
        }
        return null;
      })
      .then((data) => {
        console.log(data);
        loadPricelists(data);
      });
  };

  const assignPricelist = (pricelist, user) => {
    console.log(pricelist, user);
    user.pricelist_id = pricelist._id;
    updateUser(user);
    window.setTimeout(() => {
      loadPricelists();
    }, 300);
  };

  useEffect(() => {
    loadPricelists();
    loadUsers((data) => setUsers(data));
  }, []);

  return (
    <div>
      <OurHeader history={props.history}></OurHeader>
      <div class="container">
        <table class="table">
          <thead>
            <tr>
              <th>Pricelist Name</th>
              <th>Pricelist Date</th>
              {users.map((u) => {
                return <th scope="col">{u.username}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {pricelists.length &&
              pricelists.map((u) => {
                return (
                  <tr key={u._id}>
                    <th>{u.name}</th>
                    <th>{u.date}</th>
                    {users.map((user) => {
                      return user.pricelist_id === u._id ? (
                        <th scope="row">
                          <button
                            class="btn btn-danger"
                            onClick={() => removePricelist(u._id)}
                          >
                            ASSIGNED
                          </button>
                        </th>
                      ) : (
                        <th scope="row">
                          <button
                            class="btn btn-success"
                            onClick={() => assignPricelist(u, user)}
                          >
                            ASSIGN
                          </button>
                        </th>
                      );
                    })}{" "}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default AssignPricelists;
